import { graphql } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import ProductHighlight, {
  ProductContainer,
} from "../components/product/product-highlight"
import SEO from "../components/seo"
import { useFilterContext } from "../context/filter-context"
import { useInterfaceContext } from "../context/interface-context"
import StoreContext from "../context/store-context"
import Medusa from "../services/api"
import FilterIcon from "../assets/icons/filter.svg"

// FILTER BAR
const FilterBarContainer = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.height[1]}px;
  padding: 0 20px;

  ${({ theme }) => theme.bp.desktop} {
    display: flex;
    background-color: ${({ theme }) => theme.colors.background};
    position: sticky;
    top: 0;
    z-index: 100;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const FilterBarLeft = styled.div`
  display: flex;
  align-items: center;
`

const FilterBarLeftItem = styled.div`
  display: none;

  & + & {
    margin-left: 30px;
    padding-left: 30px;
    border-left: ${({ theme }) => theme.borders.thin};
  }

  > button {
    display: block;
    padding: 0;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.darkGreen};
    cursor: pointer;
  }

  ${({ theme }) => theme.bp.desktop} {
    display: flex;
    align-items: center;
    height: 60px;
  }
`

const FilterBarToggleFilters = styled.button`
  letter-spacing: 0.06em;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 10px;
  }
`

const FilterBarClearFilters = styled.button`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

// PROGRESS
const Progress = styled.div`
  margin: 60px auto 0 auto;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  text-align: center;

  ${({ theme }) => theme.bp.desktop} {
    min-width: 180px;
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }
`

const ProgressText = styled.p`
  margin-bottom: 0;
  letter-spacing: 0.06em;
`

const ProgressBar = styled.div`
  width: 100%;
  max-width: 240px;
  margin-top: 15px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.black_10};
`

const ProgressBarCurrent = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.darkGreen};
`

// MORE BUTTON
const LoadMoreButton = styled.button`
  display: inline-block;
  min-width: 160px;
  padding: 13px 20px;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  border: none;
  border-radius: 26px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;
  margin-bottom: 30px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGreen};
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    min-width: 180px;
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }
`

const StickyToggleFilterButton = styled.button`
  display: inline-block;
  min-width: 140px;
  padding: 13px 20px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  border: none;
  border-radius: 26px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;
  position: sticky;
  bottom: 20px;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 10px;

    path {
      stroke: ${({ theme }) => theme.colors.white};
    }

    circle {
      stroke: ${({ theme }) => theme.colors.white};
      fill: ${({ theme }) => theme.colors.darkGreen};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGreen};
    color: ${({ theme }) => theme.colors.white};

    svg {
      path {
        stroke: ${({ theme }) => theme.colors.white};
      }

      circle {
        stroke: ${({ theme }) => theme.colors.white};
        fill: ${({ theme }) => theme.colors.lightGreen};
      }
    }
  }

  ${({ theme }) => theme.bp.desktop} {
    display: none;
  }
`

const CategoryPage = ({ data, pageContext }) => {
  const [productWithQuantity, setProductWithQuantity] = useState([])
  const { toggleFilter } = useInterfaceContext()
  const { cart } = useContext(StoreContext)
  const { contentfulCategory: category } = data
  const { productGroups } = category

  const {
    products,
    displaySelected,

    setFilterOptions,
    setDisplayOptions,
    setProductsSource,

    getFilterCount,

    filterClearClickHandler,
    filterMoreClickHandler,
  } = useFilterContext()

  // UseEffect
  useEffect(() => {
    if (pageContext) {
      setFilterOptions({ ...pageContext.filterOptions })
      setDisplayOptions({ ...pageContext.displayOptions })
    }
  }, [pageContext])

  useEffect(() => {
    if (productGroups) {
      setProductsSource([...productGroups.flatMap((pg) => pg.products)])
    }
  }, [productGroups])

  useEffect(() => {
    const fetchAllVariants = async () => {
      if (products?.length) {
        let query = products
          .filter(Boolean)
          .map((p) => p.objectId)
          .join(",")

        if (cart?.id) {
          query += `&cart_id=${cart.id}`
        }

        const prodQuantities = await Medusa.products.listWithInventory(query)

        setProductWithQuantity(prodQuantities?.data?.products)
      }
    }
    fetchAllVariants()
  }, [cart.id, products, cart.currencyCode])

  return (
    <>
      <SEO
        title={pageContext.metaTitle || "Palmes Tennis Society"}
        description={
          pageContext?.metaDescription?.metaDescription ||
          "Palmes Tennis Society"
        }
      />
      <FilterBarContainer>
        <FilterBarLeft>
          <FilterBarLeftItem>
            <FilterBarToggleFilters onClick={() => toggleFilter()}>
              <FilterIcon /> Filter{" "}
              {getFilterCount() > 0 && "(" + getFilterCount() + ")"}
            </FilterBarToggleFilters>
          </FilterBarLeftItem>
          {getFilterCount() > 0 && (
            <FilterBarLeftItem>
              <FilterBarClearFilters onClick={filterClearClickHandler}>
                Clear All
              </FilterBarClearFilters>
            </FilterBarLeftItem>
          )}
        </FilterBarLeft>
      </FilterBarContainer>

      {products && (
        <ProductContainer>
          {products
            .slice(0, displaySelected.perPage * displaySelected.page)
            .map((p) => {
              if (p && p.id) {
                return (
                  <ProductHighlight
                    key={p.id}
                    product={p}
                    productVariants={
                      productWithQuantity.find((prod) => prod.id === p.objectId)
                        ?.variants ?? p.variants // Fallback when Inventory response fails
                    }
                    isSoldOut={
                      productWithQuantity?.[p.objectId]?.quantity === 0
                    }
                    slugs={{
                      categorySlug: pageContext.catSlug,
                      productSlug: p.handle,
                    }}
                  />
                )
              }
            })}
        </ProductContainer>
      )}

      <StickyToggleFilterButton onClick={() => toggleFilter()}>
        <FilterIcon /> Filter{" "}
        {getFilterCount() > 0 && "(" + getFilterCount() + ")"}
      </StickyToggleFilterButton>

      {displaySelected.page * displaySelected.perPage < products.length && (
        <Progress>
          <ProgressText>
            Showing {displaySelected.page * displaySelected.perPage} /{" "}
            {products.length} products
          </ProgressText>
          <ProgressBar>
            <ProgressBarCurrent
              style={{
                width:
                  (displaySelected.page /
                    (products.length / displaySelected.perPage)) *
                    100 +
                  "%",
              }}
            ></ProgressBarCurrent>
          </ProgressBar>
          <LoadMoreButton onClick={filterMoreClickHandler}>
            Load more
          </LoadMoreButton>
        </Progress>
      )}
    </>
  )
}

export default CategoryPage

export const query = graphql`
  query ($catSlug: String!) {
    contentfulCategory(slug: { eq: $catSlug }) {
      ... on ContentfulCategory {
        id
        productGroups {
          ...ProductGroup
        }
      }
    }
  }
`
